import React, { useState } from "react";
import "./Navbar.scss";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import { images } from "../../constants";

const Navbar = ({ user, setUser }) => {

  const [toggle, setToggle] = useState(false);


  const logout = () => {
    localStorage.removeItem('auth');
    setUser(null);

  }
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <h1 className="font-bold text-[24px]">TOIL</h1>

      </div>
      <ul className="app__navbar-links">
        {["home", "services", "skills", "contact"].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
        {user && (
          <li className="app__flex p-text" >
            <div />
            <a href="#logout" onClick={logout}>Logout</a>
          </li>
        )}
        {user ? (
          <div>
            <li className="app__flex " >
              <div />
              <img src={images.user} className="user_img" alt="user_img" />

            </li>
            <li className="app__flex " >

              {user.name}
            </li>
          </div>
        ) : (
          <li className="app__flex " >
            <div />
            <Link to="/auth">SignUp</Link>
          </li>
        )}


      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {["home", "services", "skills", "contact"].map((item) => (
                <li key={item}>

                  <a href={`#${item}`} onClick={() => setToggle(false)}>{item}</a>
                </li>


              ))}

              {user && (
                <li className="app__flex " >

                  <a href="#logout" onClick={logout}>Logout</a>
                </li>
              )}
              {user ? (


                <li className="app__flex p-text " >

                  <img src={images.user} className="user_img" alt="user_img" />&nbsp;<span>{user.name}</span>
                </li>

              ) : (
                <li className="app__flex " >

                  <Link to="/auth">SignUp</Link>
                </li>
              )}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
