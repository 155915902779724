import React, { useState } from "react";
import "./Auth.scss";
import bcrypt from 'bcryptjs';
import { useNavigate, Link } from "react-router-dom";
import { writeclient, readclient } from "../../client";
import { sendMail } from "../../api";
import { useEffect } from "react";
import { Loader, FormField, CustomButton } from '../../components'


const Auth = ({ setUser, user }) => {

    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        name: "",
        username: "",
        email: "",
        password: ""
    });
    const [isSignIn, SetIsSignIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(" ")
    const [showMessage, setShowMessage] = useState(false)
    const [loading_info, setLoading_info] = useState(" ")

    const { name, username, email, password } = formData && formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const ChangeSignIn = (e) => {
        SetIsSignIn(!isSignIn)
    }

    const handleSignUp = async () => {

        if (name !== "" && username !== "" && email !== "" && password !== "") {


            setLoading(true);
            const hashedPassword = await bcrypt.hash(password, 12);
            const auth = {
                _type: "auth",
                name: name,
                username: username,
                email: email,
                password: hashedPassword
            };

            const query_username = `*[username == "${username}" && _type == "auth"  ]`
            await readclient.fetch(query_username).then(async (data) => {


                const query_email = `*[email == "${email}" && _type == "auth"]`
                if (data.length > 0) {

                    setMessage("Username already exists!")
                    setShowMessage(true)
                    setLoading(false);
                    setTimeout(() => {
                        setShowMessage(false)
                    }, 5000);

                }
                else {

                    await readclient.fetch(query_email).then(async (email_data) => {

                        if (email_data.length > 0) {
                            setMessage("Email already exists!")
                            setShowMessage(true)
                            setLoading(false);
                            setTimeout(() => {
                                setShowMessage(false)
                            }, 5000);

                        }
                        else {
                            setLoading_info("SigningUp")
                            await writeclient.create(auth).then((result) => {



                                setLoading_info("Logged In")

                                const data = {

                                    "email": "souravberaofficial@gmail.com",
                                    "name": "Sourav bera",
                                    "subject": "New user Added",
                                    "message": `Name : ${result.name}<br> Email : ${result.email}  <br> Username : ${result.username}`
                                }
                                sendMail(data)
                                setTimeout(() => {
                                    setLoading(false);
                                    setUser({
                                        'email': result.email,
                                        'name': result.name,
                                        'username': result.username,
                                        'password': result.password
                                    })
                                    localStorage.setItem("auth", JSON.stringify({
                                        'email': result.email,
                                        'name': result.name,
                                        'username': result.username,
                                        'password': result.password
                                    }))
                                    navigate('/')
                                }, 2000);


                            });

                        }

                    });


                }

            });

        }
        else {
            setMessage("Fields Cannot Be Empty")
            setShowMessage(true)
            setTimeout(() => {
                setShowMessage(false)

            }, 5000);
        }


    };

    const handleSignIn = async () => {

        const query = `*[email == "${email}" && _type == "auth"]`
        await readclient.fetch(query).then(async (data) => {
            if (data.length <= 0) {
                setMessage("User Doesn't Exists!")
                setShowMessage(true)
                setTimeout(() => {
                    setShowMessage(false)
                }, 5000);
            } else {
                setLoading(true);
                const oldUser = data[0]
                const isPasswordCorrect = await bcrypt.compare(password, oldUser.password);
                if (!isPasswordCorrect) {
                    setMessage("Invalid credentials")
                    setShowMessage(true)
                    setTimeout(() => {
                        setShowMessage(false)

                    }, 5000);
                } else {
                    setLoading_info("Logged In")
                    setTimeout(() => {
                        setLoading(false);
                        setUser({
                            'email': oldUser.email,
                            'name': oldUser.name,
                            'username': oldUser.username,
                            'password': oldUser.password
                        })
                        localStorage.setItem("auth", JSON.stringify({
                            'email': oldUser.email,
                            'name': oldUser.name,
                            'username': oldUser.username,
                            'password': oldUser.password
                        }))
                        navigate('/')

                    }, 2000);

                }
            }
        });

    }

    useEffect(() => {
        if (user) navigate("/")
    },)


    return (
        <div className="flex justify-center  h-screen app__auth">
            <div className="border-solid  border-2 shadow-lg shadow-indigo-500/30 flex  justify-center max-w-[450px] 
            mt-[90px] font-epilogue items-center flex-col rounded-[20px] sm:p-10 p-3 max-h-[500px]">
                {loading && <Loader loading_info={loading_info} />}
                <Link to="/"><h2 className="auth_logo text-[24px] font-bold">TOIL</h2></Link>
                <div className="flex justify-center items-center p-[4px]   sm:min-w-[380px]  rounded-[10px]">
                    <h1 className=" font-bold sm:text-[25px] text-[18px] leading-[38px] text-black">{isSignIn ? 'SignIn' : 'SignUp'}</h1>
                </div>
                <div className={`app_auth_message font-bold ${showMessage ? 'app_auth_message_show' : 'app_auth_message_hidden'} `}>
                    {message}
                </div>
                <div className={`app_auth_message_sub font-bold ${showMessage ? 'app_auth_message_show' : 'app_auth_message_hidden'} `}></div>
                <div className={`app_auth_message_sub_sub font-bold ${showMessage ? 'app_auth_message_show' : 'app_auth_message_hidden'} `}></div>

                <form className="w-full mt-[40px] flex flex-col gap-[15px]" >
                    {!isSignIn && (
                        <div className="flex flex-wrap gap-[25px]">
                            <FormField
                                labelName="Full Name"
                                placeholder="John Doe"
                                inputType="text"
                                value={name}
                                name="name"
                                handleChange={handleChangeInput}
                            />
                            <FormField
                                labelName="Username"
                                placeholder="JohnDoe542"
                                inputType="text"
                                value={username}
                                name="username"
                                handleChange={handleChangeInput}
                            />
                        </div>

                    )}

                    <FormField
                        labelName="Email"
                        placeholder="johndoe@gmail.com"
                        inputType="email"
                        name="email"
                        value={email}
                        handleChange={handleChangeInput}
                    />
                    <FormField
                        labelName="Password"
                        placeholder="*********"
                        inputType="password"
                        name="password"
                        value={password}
                        handleChange={handleChangeInput}
                    />
                    <p>{isSignIn ? "Don't have a account?" : 'ALREADY HAVE AN ACCOUNT ?'} <a href={`#${isSignIn} ? 'SIGNUP' : 'SIGNIN'`} className="auth__already_sign_in" onClick={ChangeSignIn}>{isSignIn ? 'SIGNUP' : 'SIGNIN'}</a></p>

                    <div className="flex justify-center items-center mt-[30px] mb-[10px]">
                        {isSignIn ? (
                            <CustomButton
                                handleClick={handleSignIn}
                                btnType="button"
                                title={loading ? "SigningIn" : "SignIn"}
                                styles="bg-[#1dc071]"
                            />
                        ) : (
                            <CustomButton
                                handleClick={handleSignUp}
                                btnType="button"
                                title={loading ? "SigningUp" : "SignUp"}
                                styles="bg-[#1dc071]"
                            />
                        )}
                    </div>

                </form>
            </div>
        </div>


    );
};

export default Auth;
