import React from "react";

const NavigationDots = ({ active }) => {
  return (
    <div className="app__navigation">

      {active && ["home", "services", "skills", "testimonials", "contact"].map(
        (item, index) => (
          <a
            key={item + index}
            className="app__navigation-dot"
            style={active === item ? { backgroundColor: '#313BAC' } : {}}
            href={`#${item}`}> </a>
        )
      )}
    </div>
  );
};

export default NavigationDots;
