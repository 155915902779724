import React, { useEffect, useState } from 'react';
import { About, Footer, Skills, Testimonial, Header } from './container/index';
import { Navbar, Auth } from './components/index';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';

const Home = ({ user, setUser }) => {

  return (
    <div className='app'>
      <Navbar user={user} setUser={setUser} />
      <Header />
      <About />
      {/* <Work/> This needs to be show After 50 project */}
      <Skills />
      <Testimonial />
      <Footer />
    </div>
  )
}
function App() {
  const [user, setUser] = useState({});

  useEffect(() => {
    window.onload = () => {
      setUser(JSON.parse(localStorage.getItem('auth')))



    }



  }, [user])


  return (
    <BrowserRouter>
      <Routes>

        <Route path="/" exact element={<Home user={user} setUser={setUser} />} />
        <Route path="/auth" exact element={<Auth user={user} setUser={setUser} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;