import React, { useEffect, useState } from "react";
import "./About.scss";
import { motion } from "framer-motion";
import { urlFor, readclient } from '../../client';
import { AppWrap, MotionWrap } from '../../wrapper';



const About = () => {
  const [about, setabout] = useState([]);
  const [showAll, setshowAll] = useState(false);
  const showAllServices = (e) => {
    if (showAll) {
      setshowAll(false)

      document.getElementById("showAll").innerText = "See All Services"
    } else {
      setshowAll(true)

      document.getElementById("showAll").innerText = "Show Less"
    }
  }

  const selectService = (e) => {

    document.getElementById("service").value = e.target.alt
    document.getElementById("contact").scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {

    const query = '*[_type =="about"]';

    readclient.fetch(query).then((data) => {
      if (!showAll) {
        data = data.slice(0, 6)
      }

      setabout(data);
    })

  }, [showAll])


  return (
    <>
      <h2 className="head-text">
        {" "}
        We Know that <span>Good Apps</span> <br /> means{" "}
        <span>Good Business.</span>
      </h2>

      <div className="app__profiles">
        {about.map((about_item, index) => (

          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
            key={about_item.title + index}
            onClick={selectService}
          >

            <img src={urlFor(about_item.imgUrl)} alt={about_item.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>
              {about_item.title}
            </h2>
            <p className="p-text" style={{ marginTop: 10 }}>
              {about_item.description}
            </p>
          </motion.div>

        ))}

      </div>
      <button id="showAll" type="button" className="p-text" onClick={showAllServices}>See All Services</button>
    </>
  );
};

export default AppWrap(MotionWrap(About, "app__about"), 'services', 'app__whitebg');
