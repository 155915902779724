import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';


export const writeclient = sanityClient({
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: 'production',
    apiVersion: '2022-12-01',
    useCdn: false,
    token: process.env.REACT_APP_SANITY_TOKEN,
    ignoreBrowserTokenWarning: true,
});

export const readclient = sanityClient({
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: 'production',
    apiVersion: '2022-12-01',
    useCdn: true,
    ignoreBrowserTokenWarning: true,
});


const builder = imageUrlBuilder(readclient);
export const urlFor = (source) => builder.image(source);